<template>
    <div class="section">
        <div class="container">
            <div>
                <h2 class="title is-2 is-uppercase">Register</h2>
                <hr />
                <div class="columns">
                    <div class="column is-6">
                        <o-field label="Email">
                            <o-input v-model="form.username" @change="v.form.username.$touch()"></o-input>
                        </o-field>
                        <o-field label="Password">
                            <o-input type="password" v-model="form.password" @change="v.form.password.$touch()"></o-input>
                        </o-field>
                        <o-field label="Confirm Password">
                            <o-input type="password" v-model="form.passwordConfirm" @change="v.form.passwordConfirm.$touch()"></o-input>
                        </o-field>
                        <o-field label="Address">
                            <o-input v-model="form.address"></o-input>
                        </o-field>
                        <o-field label="Zip">
                            <o-input v-model="form.zip"></o-input>
                        </o-field>
                        <o-field label="City">
                            <o-input v-model="form.city"></o-input>
                        </o-field>
                        <o-field label="State">
                            <o-select v-model="form.stateId" placeholder="Select a State" expanded>
                                <option v-for="state in options.states" :value="state.Id" :key="state.Id">{{state.Name}}</option>
                            </o-select>
                        </o-field>
                    </div>
                    <div class="column is-6">
                        <o-field label="First Name">
                            <o-input v-model="form.firstname" @change="v.form.firstname.$touch()"></o-input>
                        </o-field>
                        <o-field label="Last Name">
                            <o-input v-model="form.lastname" @change="v.form.lastname.$touch()"></o-input>
                        </o-field>
                        <o-field label="Company">
                            <o-input v-model="form.company"></o-input>
                        </o-field>
                        <o-field label="Phone:"
                                 :variant="v.form.phone.$invalid && v.form.phone.$dirty ? 'danger' : ''">
                            <template v-slot:message>
                                {{ v.form.phone.$invalid && v.form.phone.$dirty ? "Must have a phone or fax" : "" }}
                            </template>
                            <phone-number v-model="form.phone"
                                          @change="v.form.phone.$touch()"></phone-number>
                        </o-field>
                        <o-field label="Fax">
                            <o-input v-model="form.fax" @change="v.form.fax.$touch()"></o-input>
                        </o-field>
                    </div>
                </div>
                <div>
                    <o-button @click="Register" :disabled="v.$invalid">Register</o-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    import useValidate from "@vuelidate/core";
    import { required, email } from "@vuelidate/validators";
    import { PhoneNumber } from "@/components/input";
    export default {
        components: {
            PhoneNumber
        },
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const form = ref({});
            const options = ref({});

            const v = useValidate(
                {
                    form: {
                        username: {
                            required,
                            email
                        },
                        password: {
                            required,
                            Equals() {
                                return form.value.password === form.value.passwordConfirm;
                            }
                        },
                        passwordConfirm: {
                            required,
                            Equals() {
                                return form.value.password === form.value.passwordConfirm;
                            }
                        },
                        firstname: {
                            required,
                        },
                        lastname: {
                            required,
                        },
                        phone: {
                            AtleastOne() {
                                return !!form.value.phone || !!form.value.fax;
                            }
                        },
                        fax: {
                            AtleastOne() {
                                return !!form.value.phone || !!form.value.fax;
                            }
                        }
                    }
                },
                { form }
            );

            const Register = async () => {
                try {
                    var response = await $http.post("/api/user/register", form.value);
                    global.$store.dispatch("login", response.data);
                    $success("Successfully registered.");
                    global.$router.push("rfq");
                }
                catch (err) {
                    $error("Failed to register.");
                }
            };

            const GetStates = async () => {
                var response = await $http.get("/api/state");
                options.value.states = response.data;
                return response;
            };

            const IsInvalid = () => {
                return form.value.password != form.value.passwordConfirm;
            };

            onMounted(() => {
                GetStates();
            });

            return {
                v,
                form,
                options,
                Register,
                IsInvalid,
                GetStates
            }
        }
    }
</script>